import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { OrthographicCamera, Environment, Lightformer, MeshTransmissionMaterial, RenderTexture, OrbitControls } from '@react-three/drei'
import { OYE } from './OYE'
import * as THREE from 'three'
import { useEffect } from 'react'

const vec = new THREE.Vector3()

function Rig() {
  return useFrame(({ camera, mouse }) => {
    if(window.innerWidth > window.innerHeight){
      vec.set(mouse.x * 5, mouse.y * 5, camera.position.z)
    }else{
      vec.set(mouse.x * 5, mouse.y * 5, 75)

    }
    camera.position.lerp(vec, 0.025)
    camera.lookAt(0, 0, 0)
  })
}

export const SimpleLogoScene = () => (

  <Canvas camera={{ position: [0, 0, 45], zoom: 10 }} dpr={0.8}>
    <FrostedGlass>
      <OYE />
      <Ambience />
    </FrostedGlass>
    <Rig />
  </Canvas>
)

function Ambience() {
  // Swirl camera around (we're inside the render texture)

  // Render a custom environment map, this is what the sphere reflects
  return (
    <Environment files={'textures/studio_small_03_1k.hdr'} background resolution={512}>
      <group rotation={[-Math.PI / 4, 0, 0]}>
        <Lightformer intensity={1} rotation-x={Math.PI / 2} position={[0, 5, -9]} scale={[2, 2, 1]} />
        {[2, 0, 2, 0, 2, 0, 2, 0].map((x, i) => (
          <Lightformer key={i} form="circle" intensity={1} rotation={[Math.PI / 2, 0, 0]} position={[x, 4, i * 4]} scale={[2, 1, 1]} />
        ))}
      </group>
    </Environment>
  )
}

function FrostedGlass({ children }) {
  const { width, height } = useThree((state) => state.viewport)
  return (
    <mesh scale={[width, height, 1]}>
      <planeGeometry />
      <MeshTransmissionMaterial color={'white'} samples={10} ior={1.17} transmission={1} thickness={0.1} chromaticAberration={0.} anisotropy={1} roughness={0.15} distortion={0.0}  temporalDistortion={0.0}>
        <RenderTexture attach="buffer">{children}</RenderTexture>
      </MeshTransmissionMaterial>
    </mesh>
  )
}
