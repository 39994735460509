
import React, {  useEffect, useRef, useState } from "react";
import * as THREE from 'three'
import { useFrame, useLoader } from "@react-three/fiber";

import { useGLTF } from '@react-three/drei'
import { TextureLoader } from 'three/src/loaders/TextureLoader'; 

const vec = new THREE.Vector3()



export function OYE(props) {
  //const { scene } = useGLTF('/D3/glaive-sigle-wt.glb')
  const { scene } = useGLTF('/D3/oye-sigle-b.glb')
  const meshRef = useRef()
  const asterixRef = useRef()
  const cigleRef = useRef()
  const [ normalMap, roughnessMap, metalnessMap] = useLoader(TextureLoader, [ 
    './textures/Metal010_1K_NormalGL.jpg', 
    './textures/Metal010_1K_Roughness.jpg', 
    './textures/Metal010_1K_Metalness.jpg', 
  ]) 

  const materialProps = {
    color: '#434343',
    metalness: 0.8,
    metalnessMap: metalnessMap,
    roughness: 0.3,
    roughnessMap: roughnessMap,
    normalMap: normalMap,
    clearcoatRoughness: 0, 
    envMapIntensity: 7
  }
  const [vec] = useState(() => new THREE.Vector3())

  const blurLister = document.querySelector('.client-wrapper')

  useEffect( () => {

    scene.traverse((object) => {

      meshRef.current.rotation.x = -Math.PI/2
      meshRef.current.rotation.z = 0.6
      //meshRef.current.rotation.z = t * 0.5 
    });

  }, [])

  useFrame((state, delta) => {
    if(blurLister.style !== undefined && blurLister.style !== ""){
        let itemStyle = blurLister.style
        if(itemStyle.cssText != undefined){
            console.log(itemStyle.cssText, "one blut")

        }
    }
    let t = state.clock.getElapsedTime();
    //meshRef.current.position.lerp(vec.set(state.mouse.x * 0.3, state.mouse.y * 0.3, 0), 0.05)
    scene.traverse((object) => {
      asterixRef.current.rotation.y += delta * .1
      //cigleRef.current.rotation.z -= delta * .1

      //meshRef.current.rotation.x = t * 0.5 
      meshRef.current.rotation.z = t * 0.5 
    });

  

  });


  const scaleItem = 1900
  return (
  <>
    <group {...props} ref={meshRef}  position={[0, -0.5, 0]} >
      <mesh ref={asterixRef}  scale={[scaleItem, scaleItem, scaleItem]} geometry={scene.children[0].geometry} position={[-3, 0, 1.9]}>
        <meshStandardMaterial  {...materialProps} side={THREE.DoubleSide} envMapIntensity={7} />
      </mesh>
      <mesh ref={cigleRef} scale={[scaleItem, scaleItem, scaleItem]} geometry={scene.children[1].geometry} position={scene.children[1].position}>
        <meshStandardMaterial  {...materialProps} side={THREE.DoubleSide} envMapIntensity={7} />
      </mesh>
    </group>
  </>)
  
}
