import { ContactShadows, Environment, OrbitControls, Preload, SoftShadows, useGLTF } from "@react-three/drei"
import { Canvas, useFrame, useThree } from "@react-three/fiber"
import { Suspense, useEffect, useRef, useState } from "react"
import { OYE } from "./OYE"
import { Effects } from "./Effects"
import Ground from "./Ground"
import Video from "./Video"
import { Daddy, Sophia } from "./People"

const App = () => {
    const [camPos, setCampPos] = useState({x: 5, y: 0.5, z: 3})
    const [video] = useState(() =>
        Object.assign(document.createElement('video'), { src: '/videos/oye-showreal-2.mp4', crossOrigin: 'Anonymous', loop: true, muted: true, playsInline:true  }),
    )



    useEffect( () => {
        if(window.innerWidth < window.innerHeight){
            setCampPos({x: 40, y: 0.5, z: 120})
        }
    }, [])

    return (
        <>
            <Canvas shadows gl={{ antialias: true, stencil: false }}  camera={{ position: [camPos.x, camPos.y, camPos.z], fov: 50 }} >
                <color attach="background" args={['#000']} />
                <fog attach="fog" args={['#000', 30, 40]} />
                <pointLight position={[0, 5, 0]} intensity={3} />
                <perspectiveCamera 
                    position={[camPos.x, camPos.y, camPos.z]} 
                    fov={40}
                />
                <group rotation={[0, 0, 0]}>
                        <Video video={video} />
                        <Sophia rotation={[0, Math.PI, 0]} scale={0.003} position={[1, 0, 2.5]} />
                        <Daddy rotation={[0, Math.PI, 0]} scale={0.3} position={[0, 0, 2.2]} />
                        <Ground />
                </group>
                <Effects />
                <OrbitControls 
                    position={[0, 1., 0]}
                    target={[0, 1, 2]}
                    enablePan={false}
                    maxPolarAngle={Math.PI * 0.55} 
                    minAzimuthAngle={-Math.PI / 2}
                    maxAzimuthAngle={Math.PI / 2}
                    minDistance={4}
                    maxDistance={6.}
                />
            </Canvas>
        </>
    )
}

export default App